


function loadClose () {
  setTimeout(function () {
    $('#loader-bg').fadeOut(1100); //$('#loader-bg').fadeOut(800);でも可
    $('#animation_container').css('display', 'block'); // ページ読み込みが終わったらメインコンテンツを表示する
  }, 7300);
}


$(function () {

  if ($.cookie("access") == undefined) {// 初回アクセスか判定
      $.cookie("access", "onece", { expires: 1 });//Cookieに保存(24時間でリセット)

      
      var h = $(window).height(); //ブラウザウィンドウの高さを取得
      $('#animation_container').css('display', 'none'); //初期状態ではメインコンテンツを非表示
      $('#loader-bg').height(h).css('display', 'block'); //ウィンドウの高さに合わせでローディング画面を表示


      $(window).load(function () {
        
        var mySVG = $('.anime--svg').drawsvg({
          duration: 20, //ひとつのpathが描き終わる速度
          stagger: 0, //次のpathを書き始めるまでの遅延時間。
          easing: 'swing', //jqueryで使用できるイージングを実装。
          reverse: false, //描く方向の指定。
          callback: function () {
            console.log('aaa')

            //コールバックの処理を記述
            $('.st02').delay(1000).queue(function (next) {
              $(this).addClass('on');
            });
            $('.st01').delay(200).queue(function (next) {
              $(this).addClass('on');
            });
            $('.st03').delay(1000).queue(function (next) {
              $(this).addClass('on');
            });
          }
        });
        mySVG.drawsvg('animate');
        loadClose();
      });

      $(function () {
        var $closeLoading = $('#closeBtn');
        // アコーディオン内クリック時
        $closeLoading.on('click', function (e) {
          $('#loader-bg').fadeOut(1100); //$('#loader-bg').fadeOut(800);でも可
          $('#animation_container').css('display', 'block'); // ページ読み込みが終わったらメインコンテンツを表示する
          console.log("スマホボタン")
        });
      });

    }

});



// PCの場合の動画再生
function pcVideo() {
  var videoCont = document.getElementById("video");
  $('#videoSP').hide();
  function playVideo() {
    //動画を再生
    videoCont.play();
  }
  setTimeout(function () {
    //loadingフェードアウトのタイミングで動画再生
    playVideo();
  }, 1000);
}

// SPの場合の動画再生
function spVideo() {
  var videoCont = document.getElementById("videoSP");
  $('#video').hide();
  function playVideo() {
    //動画を再生
    videoCont.play();
  }
    setTimeout(function () {
      //loadingフェードアウトのタイミングで動画再生
      playVideo();
    }, 1000);  
}


var getDeviceLoad = (function () {
  var winW = $(window).width();
  var devW = 500;
  if (winW <= devW) {
    /*-------------------------------------------------------------------------*/
    /* SPの時
    /*-------------------------------------------------------------------------*/
    $(window).load(function () {
      spVideo();
    });
  }else{

    /*-------------------------------------------------------------------------*/
    /* PCの時
    /*-------------------------------------------------------------------------*/
    $(window).load(function () {
      pcVideo();
    });
  }
})();


$(function () {
  var timer = false;
  var prewidth = $(window).width();
  $(window).resize(function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      var nowWidth = $(window).width();
      if (prewidth !== nowWidth) {
        // リロード
        location.reload();
      }
      prewidth = nowWidth;
    }, 200);
  });
});

